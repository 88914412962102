import { http } from '@/http';

const path = '/extrato';

class ExtratoService {

  static async findAll(idCliente, filtro, idConfigPortal, vencimento, filtroSituacao, filtroOrigem, filtroParcela) {
    try {
      const { data } = await http.get(`${path}/all?idCliente=${idCliente}&filtro=${filtro}&idConfigPortal=${idConfigPortal}&vencimento=${vencimento}&filtroSituacao=${filtroSituacao}&filtroOrigem=${filtroOrigem}&filtroParcela=${filtroParcela}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os extratos.', error);
      throw error;
    }
  }

  static async findEnglobamentos(idPessoa) {
    try {
      const { data } = await http.get(`${path}/englobamentos?idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar os englobamentos.', error);
      throw error;
    }
  }

  static async atualizarVencimento(url, atualizarVencimento) {
    try {
      const { data } = await http.post(`${path}/atualizarParcela?url=${url}`, atualizarVencimento);
      return data;
    } catch (error) {
      console.log('Erro ao atualizar vencimento.', error);
      throw error;
    }
  }

  static async imprimirDam(url, imprimirDam) {
    try {
      const config = {
        responseType: 'arraybuffer',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/pdf',
        },
      };

      const { data } = await http.post(`${path}/imprimirDam?url=${url}`, imprimirDam, config);

      const blob = new Blob([data], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    } catch (error) {
      console.log('Erro ao imprimir DAM.', error);
      throw error;
    }
  }

  static async englobar(url, englobar) {
    try {
      const { data } = await http.post(`${path}/englobar?url=${url}`, englobar);
      return data;
    } catch (error) {
      console.log('Erro ao englobar registros.', error);
      throw error;
    }
  }

  static async findInscricao(idCliente, filtro, idConfigPortal, tipoCadastro) {
    try {
      const { data } = await http.get(`${path}/buscarInscricoes?idCliente=${idCliente}&filtro=${filtro}&idConfigPortal=${idConfigPortal}&tipoCadastro=${tipoCadastro}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar inscricoes.', error);
      throw error;
    }
  }

  static async criptografar(filtro, modo) {
    try {
      const { data } = await http.get(`${path}/cript?filtro=${filtro}&modo=${modo}`);
      return data;
    } catch (error) {
      console.log('Erro ao criptografar variável.', error);
      throw error;
    }
  }

  static async getIdOrigemPermitido(idCliente){
    try {
      const { data } = await http.get(`${path}/idOrigemCalculoPermitido?idCliente=${idCliente}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar id origem calculo.', error);
      throw error;
    }
  }



}

export default ExtratoService;