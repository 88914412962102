<template>
  <div class="container-fluid">
    <Dialog 
      v-model:visible="displayModal"
      header="Atualizar Vencimento"
      :breakpoints="{ '960px': '25vw', '640px': '20vw' }"
      :style="{ width: '15vw' }"
      :modal="true"
      class="bg-dark navbar-nav"
    >

      <div class="container-fluid">
        <div class="row row-no-gutters">
          <div class="col-12">
            <label for="dataVencimento">Novo Vencimento:</label>
            <span class="p-fluid">
              <Calendar
                id="dataVencimento" 
                v-model="atualizarVencimento.dataVencimento"
                :showIcon="true"
                dateFormat="dd/mm/yy"
                placeholder="Data de Vencimento"
                :class="atualizarVencimento.dataVencimentoHelp ? 'p-invalid' : ''"
              />
              <small
                id="dataVencimento-help"
                class="p-error"
                v-if="atualizarVencimento.dataVencimentoHelp"
              >
                Data de vencimento incorreta.
              </small>
            </span>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="hide" class="p-button-secondary" autofocus/>
        <Button label="Atualizar" icon="pi pi-refresh" @click="onSubmitForm" />
      </template>

    </Dialog>
  </div>
</template>
<script>
import moment from "moment";
import ExtratoService from "@/services/extrato.service";

export default {
  name: "AtualizarVencimento",
  data() {
    return {
      extrato: null,
      displayModal: false,
      atualizarVencimento: {
        dataVencimento: null,
        dataVencimentoHelp: false,
      },
    };
  },
  computed: {
    serverExec() {
      return this.$store.getters.getServerExec;
    },
  },
  methods: {
    show(extratoShow) {
      this.extrato = extratoShow;
      this.atualizarVencimento.dataVencimento = null;
      this.displayModal = true;
    },

    hide() {
      this.extrato = null;
      this.displayModal = false;
    },

    clearValidation() {
      this.atualizarVencimento.dataVencimentoHelp = false;
    },

    onSubmitForm() {
      if (this.validation()) {
        this.onSubmit();
      }
    },

    validation() {
      if (this.atualizarVencimento.dataVencimento === null) {
        this.$toast.add({
          severity: "warn",
          summary: "Informe a data de vencimento.",
          life: 3000,
        });

        this.atualizarVencimento.dataVencimentoHelp = true;

        return false;
      }

      /*if (moment(this.atualizarVencimento.dataVencimento, 'DD/MM/YYYY').utc().valueOf() <= moment().utc().valueOf()) {

        this.$toast.add({
          severity: "error",
          summary: "A data de vencimento deve ser superior a data de hoje.",
          life: 3000,
        });

        this.atualizarVencimento.dataVencimentoHelp = true;

        return false;
      }*/

      this.atualizarVencimento.dataVencimentoHelp = false;

      return true;
    },

    async onSubmit() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);

        await this.$root.getTrbToken;
        
        const data = {
          idCalculoParcelados: this.extrato.pid,
          dataParaCalculo: moment(this.atualizarVencimento.dataVencimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          token: this.$store.getters.getTrbToken,
        };
        const result = await ExtratoService.atualizarVencimento(this.serverExec,data);

        if (result && result.false) {
          this.$toast.add({
            severity: "error",
            summary: result.false,
            life: 3000,
          });
        } else if (result && result.true) {
          this.$toast.add({
            severity: "success",
            summary: result.true,
            life: 3000,
          });

          this.$emit('refreshExtratos');
          this.hide();
        }

        console.log(result);

      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao atualizar vencimento!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    }

  },
  
};
</script>

<style>

  .p-dialog .p-dialog-header {
    background-color: var(--surface-300) !important;
  }

</style>