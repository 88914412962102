import axios from "axios";

const API_URL = '/relatorio/';
//const CLIENT_URL = process.env.VUE_APP_BASE_URL_CLIENT;

class ReportService {
  constructor() {
  }



  imprimir(rel, parametros, tipo = "pdf") {

    console.log(parametros);

    return axios({
      url: API_URL + "export",
      method: "POST",
      responseType: "blob",
      data: {
        rel: rel,
        tipo: tipo,
        parametros: parametros
      },      
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true'
      }
    }).then(response => {
      var fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(fileURL);
      console.log(response.data);
    });
  }

  download(rel, parametros, tipo = "pdf") {
    return axios({
      url: API_URL + "export",
      method: "POST",
      responseType: "blob",
      data: {
        rel: rel,
        tipo: tipo,
        parametros: parametros
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      var fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      console.log(response);
      fileLink.setAttribute("download", rel + "." + tipo);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

}

export default new ReportService();
