<template>
  <div class="container-fluid">
    <Dialog
      v-model:visible="displayModal"
      header="Englobar"
      :breakpoints="{ '960px': '25vw', '640px': '20vw' }"
      :style="{ width: '15vw' }"
      :modal="true"
      class="bg-dark navbar-nav"
    >
      <div class="container-fluid">
        <div class="row row-no-gutters">
          <div class="col-12 p-1">
            <label for="dataVencimento">Data de Vencimento:</label>
            <span class="p-fluid">
              <Calendar
                id="dataVencimento"
                v-model="englobar.dataVencimento"
                :showIcon="true"
                dateFormat="dd/mm/yy"
                placeholder="Data de Vencimento"
                :class="englobar.dataVencimentoHelp ? 'p-invalid' : ''"
              />
              <small
                id="dataVencimento-help"
                class="p-error"
                v-if="englobar.dataVencimentoHelp"
              >
                Data de vencimento incorreta.
              </small>
            </span>
          </div>

          <div class="col-12 p-1">
            <label for="observacao">Observação:</label>
            <div class="p-fluid">
              <Textarea
                v-model="englobar.observacao"
                rows="5"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          @click="hide"
          class="p-button-secondary"
          autofocus
        />
        <Button
          label="Englobar"
          icon="pi pi-calculator"
          @click="onSubmitForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import moment from "moment";
import ExtratoService from "@/services/extrato.service";

export default {
  name: "englobar",
  data() {
    return {
      extratoSelecteds: null,
      displayModal: false,
      englobar: {
        dataVencimento: null,
        dataVencimentoHelp: false,
        observacao: "",
      },
    };
  },
  computed: {
    serverExec() {
      return this.$store.getters.getServerExec;
    },
  },

  methods: {
    show(extratoSelecteds) {
      this.extratoSelecteds = extratoSelecteds;
      this.englobar.dataVencimento = null;
      this.englobar.observacao = "";
      this.displayModal = true;
    },

    hide() {
      this.extratoSelecteds = null;
      this.displayModal = false;
    },

    clearValidation() {
      this.englobar.dataVencimentoHelp = false;
    },

    onSubmitForm() {
      if (this.validation()) {
        this.onSubmit();
      }
    },

    validation() {
      if (this.englobar.dataVencimento === null) {
        this.$toast.add({
          severity: "warn",
          summary: "Informe a data de vencimento.",
          life: 3000,
        });

        this.englobar.dataVencimentoHelp = true;

        return false;
      }

      if (
        moment(moment(this.englobar.dataVencimento).format("DD/MM/YYYY"))
          .utc()
          .valueOf() < moment(moment().format("DD/MM/YYYY")).utc().valueOf()
      ) {
        this.$toast.add({
          severity: "warn",
          summary: "A data de vencimento deve ser superior a data de hoje.",
          life: 3000,
        });

        this.englobar.dataVencimentoHelp = true;

        return false;
      }

      this.englobar.dataVencimentoHelp = false;

      return true;
    },

    async onSubmit() {
      try {
        this.$store.dispatch("SET_ENABLE_SPINNER", true);

        await this.$root.getTrbToken;

        const data = {
          idCalculoParcelados: this.extratoSelecteds
            .map((v) => v.pid)
            .toString(),
          dataParaCalculo: moment(
            this.englobar.dataVencimento,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
          mensagem: this.englobar.observacao,
          id: this.extratoSelecteds[0].pidpessoa,
          tipoId: "P",
          token: this.$store.getters.getTrbToken,
        };

        const result = await ExtratoService.englobar(this.serverExec, data);
        if (result && result.true) {
          this.$toast.add({
            severity: "success",
            summary: "Englobamento realizado com sucesso.",
            life: 3000,
          });
          this.$emit("refreshExtratos");
          this.hide();
        } else if (result && result.false) {
          this.$toast.add({
            severity: "error",
            summary: result.false,
            life: 3000,
          });
        } 
      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao englobar!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch("SET_ENABLE_SPINNER", false);
      }
    },
  },
};
</script>

<style>
.p-dialog .p-dialog-header {
  background-color: var(--surface-300) !important;
}
</style>