<template>
<div class="container" v-if="pesq">
    <div class="row justify-content-md-center">
        <div class="col-12 col-sm-12 p-1" :class="hasContribuinteTipos ? 'col-md-2' : 'col-md-4'">
            <label for="tipoTomador">Filtro:</label>
            <span class="p-fluid">
                <Dropdown v-model="selectedFiltro" :options="filtrosConfiguracao" optionLabel="tipoFiltro" placeholder="Selecione um Filtro" @change="findContribuinteTipo" />
            </span>
        </div>

        <div class="col-12 col-sm-12 col-md-2 p-1" v-if="hasContribuinteTipos">
            <label for="tipoTomador">{{
                    selectedFiltro && selectedFiltro.tipoFiltro
                    ? "Tipo de " + returnLabel()
                    : "Tipo:"
                }}
            </label>
            <span class="p-fluid">
                <Dropdown @change="buscarInscricoes()" v-model="contribuinteTipo" :options="contribuinteTipos" optionLabel="descricaoContribuinteTipo" placeholder="Selecione um Tipo" />
            </span>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 col-md-4 mb-3 p-1">
            <label for="cpfCnpj">{{ returnLabel() }}</label>
            <span class="p-fluid">
                <template v-if="this.selectedFiltro && selectedFiltro.tipoFiltro == 'CNPJ'">
                    <InputMask id="tipoTomador" @blur="validarDocumento()" v-model="filtro" key="CNPJ" mask="99.999.999/9999-99" placeholder="Informe o CNPJ" />
                </template>
                <template v-else-if="this.selectedFiltro && selectedFiltro.tipoFiltro == 'INSCRIÇÃO'
                        ">
                    <InputText id="tipoTomador" v-model="filtro" key="INSCRICAO" placeholder="Informe a Inscrição Municipal" maxlength="20" />
                </template>
                <template v-else-if="this.selectedFiltro &&
                        selectedFiltro.tipoFiltro == 'CPF_INSCRIÇÃO'
                        ">
                    <InputMask @blur="validarDocumento()" v-model="filtro" key="CPF" id="tipoTomador" mask="999.999.999-99" placeholder="Informe o CPF" />
                </template>
                <template v-else-if="this.selectedFiltro &&
                        selectedFiltro.tipoFiltro == 'CNPJ_INSCRIÇÃO'
                        ">
                    <InputMask @blur="
                            validarDocumento();
                        buscarInscricoes();
                        " v-model="filtro" key="CNPJ" id="tipoTomador" mask="99.999.999/9999-99" placeholder="Informe o CNPJ" />
                </template>
                <template v-else>
                    <InputMask @blur="validarDocumento()" v-model="filtro" key="CPF" id="tipoTomador" mask="999.999.999-99" placeholder="Informe o CPF" />
                </template>
            </span>
            <template v-if="(this.selectedFiltro &&
                    selectedFiltro.tipoFiltro == 'CNPJ_INSCRIÇÃO') ||
                    (this.selectedFiltro &&
                        selectedFiltro.tipoFiltro == 'CPF_INSCRIÇÃO')
                    ">
                <label>Inscrição</label>
                <span class="p-fluid">
                    <Dropdown v-model="inscricoes[0]" :options="inscricoes" optionLabel="inscricaoMunicipal" placeholder="Inscrições" />
                </span>
            </template>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 col-md-4 mb-3 p-1 d-flex justify-content-center">
            <span class="p-fluid">
                <vue-recaptcha ref="recaptcha" :sitekey="$store.getters.getKeyRecaptcha" @verify="handleSuccess" @expired="handleError" @render="handleError" @error="handleError" />
            </span>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-12 col-md-4 mb-3 p-1 d-flex justify-content-end">
            <Button label="Pesquisar" type="submit" class="p-button-primary" @click="pesquisar" icon="pi pi-angle-right" :disabled="captchaState" />
        </div>
    </div>
</div>

<div class="row justify-content-center" v-if="exibirInfoDados">
    <div class="col-12 col-md-4 mb-3 p-1">
        <Message severity="info" :closable="false">
            Contribuinte sem dados para exibição!
        </Message>
    </div>
</div>

<div class="row">
    <div class="col-12 p-1">
        <TabView ref="tabview" v-if="!pesq">
            <TabPanel header="Extrato">
                <div class="row row-no-gutters">
                    <div class="col-12 col-md-4 col-lg-2 p-1">
                        <label for="filtroDataVencimento">Vencimento:</label>
                        <span class="p-fluid">
                            <Calendar showIcon id="filtroDataVencimento" name="filtroDataVencimento" v-model="filtroDataVencimento" dateFormat="dd/mm/yy" placeholder="Data Vencimento" @keyup.enter="pesquisar" />
                        </span>
                    </div>

                    <div class="col-12 col-md-4 col-lg-2 p-1">
                        <label for="filtroSituacao">Situação:</label>
                        <span class="p-fluid">
                            <Dropdown id="filtroSituacao" name="filtroSituacao" v-model="selectedFiltroSituacao" :options="filtrosSituacao" optionLabel="name" placeholder="Situação" @keyup.enter="pesquisar" />
                        </span>
                    </div>

                    <div class="col-12 col-md-4 col-lg-2 p-1">
                        <label for="filtroOrigem">Origem:</label>
                        <span class="p-fluid">
                            <Dropdown id="filtroOrigem" name="filtroOrigem" v-model="selectedFiltroOrigem" :options="filtrosOrigem" optionLabel="name" placeholder="Origem" @keyup.enter="pesquisar" />
                        </span>
                    </div>

                    <div class="col-12 col-md-3 col-lg-2 p-1">
                        <label for="filtroParcela">Parcela:</label>
                        <span class="p-fluid">
                            <Dropdown id="filtroParcela" name="filtroParcela" v-model="selectedFiltroParcela" :options="filtrosParcela" optionLabel="name" placeholder="Parcela" @keyup.enter="pesquisar" />
                        </span>
                    </div>

                    <div class="col-12 col-md-9 col-lg-3 p-1 button-sem-label col-sem-label">
                        <Button @click="pesquisar" label="Pesquisar" type="button" class="p-button-primary mr-2" icon="pi pi-search" :disabled="loading" />
                        <Button @click="englobar" label="Englobar" type="button" class="p-button-primary mr-2" icon="pi pi-calculator" :disabled="loading || disableEnglobar" />
                        <Button @click="imprimirExtrato" label="Imprimir" type="button" class="p-button-primary" icon="pi pi-print" :disabled="loading" />
                    </div>

                    <div class="col-12 p-1 mt-3">
                        <DataTable v-if="this.extratoList.length > 0 && !pesq" :loading="loading" paginator showGridlines dataKey="pid" ref="dt" class="p-datatable-customers" :value="extratoList" :rows="15" :rowHover="true" v-model:filters="filters" v-model:selection="selecteds" filterDisplay="menu" responsiveLayout="scroll">
                            <template #loading> Carregando Extrato... </template>
                            <Column selectionMode="multiple" headerClass="headerSelection" bodyStyle="text-align: center" />
                            <Column field="actionsButton" header="Ações">
                                <template #body="slotProps">
                                    <div class="d-flex justify-content-center">
                                        <Button type="button" icon="pi pi-align-justify" title="Detalhamento" @click="imprimirDetalhamento(slotProps.data.pid)" class="p-button-grid p-button-sm" />
                                        <Button type="button" icon="pi pi-print" title="Imprimir" @click="imprimirDam(slotProps.data)" class="p-button-grid p-button-sm" v-if="verificaIdOrigemCalculo(slotProps.data.pidorigemcalculo)" />
                                        <Button type="button" icon="pi pi-refresh" title="Atualizar Vencimento" @click="atualizarVencimento(slotProps.data)" class="p-button-grid p-button-sm" v-if="verificaIdOrigemCalculo(slotProps.data.pidorigemcalculo)" />
                                    </div>
                                </template>
                            </Column>
                            <Column field="porigem" header="Origem" sortable>
                                <template #body="{ data }">
                                    {{ data.porigem }}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesq. Origem" />
                                </template>
                            </Column>
                            <Column field="pano" header="Ano" sortable>
                                <template #body="{ data }">
                                    {{ data.pano }}
                                </template>
                            </Column>
                            <Column field="pmes" header="Mês" sortable>
                                <template #body="{ data }">
                                    {{ data.pmes }}
                                </template>
                            </Column>
                            <Column field="pparcela" header="Parcela" sortable>
                                <template #body="{ data }">
                                    {{ data.pparcela }}
                                </template>
                            </Column>
                            <Column field="pmovimento" header="Detalhe" sortable>
                                <template #body="{ data }">
                                    {{ data.pmovimento }}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesq. Detalhe" />
                                </template>
                            </Column>
                            <Column field="psituacao" header="Situação" sortable>
                                <template #body="{ data }">
                                    {{ data.psituacao }}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesq. Situação" />
                                </template>
                            </Column>
                            <Column field="pinscricao" header="Inscrição" sortable>
                                <template #body="{ data }">
                                    {{ data.pinscricao }}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesq. Inscrição" />
                                </template>
                            </Column>
                            <Column field="psequencialcadastro" header="Inscrição Reduzida" sortable>
                                <template #body="{ data }">
                                    {{ data.psequencialcadastro }}
                                </template>
                                <template #filter="{ filterModel }">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesq. Inscrição" />
                                </template>
                            </Column>
                            <Column field="dataVencDamFormatada" hidden />
                            <Column field="pdatavencdam" header="Data Vencimento" bodyStyle="text-align: center;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.dataVencDamFormatada }}
                                </template>
                            </Column>
                            <Column field="dataPagamentoFormatada" hidden />
                            <Column field="pdatapagamento" header="Data Pagamento" bodyStyle="text-align: center;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.dataPagamentoFormatada }}
                                </template>
                                <template #footer>
                                    Total:
                                </template>
                            </Column>
                            <Column field="valorFormatado" hidden />
                            <Column field="pvalor" header="Valor" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.valorFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalOrigem }}
                                </template>
                            </Column>
                            <Column field="multaFormatado" hidden />
                            <Column field="pmulta" header="Multa" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.multaFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalMulta }}
                                </template>
                            </Column>
                            <Column field="jurosFormatado" hidden />
                            <Column field="pjuros" header="Juros" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.jurosFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalJuros }}
                                </template>
                            </Column>
                            <Column field="correcaoFormatado" hidden />
                            <Column field="pcorrecao" header="Correção" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.correcaoFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalCorrecao }}
                                </template>
                            </Column>
                            <Column field="multaInscricaoFormatado" hidden />
                            <Column field="pmultainscricao" header="Multa Insc." bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.multaInscricaoFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalMultaInscricao }}
                                </template>
                            </Column>
                            <Column field="descontoFormatado" hidden />
                            <Column field="pdescontototal" header="Desconto" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.descontoFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalDesconto }}
                                </template>
                            </Column>
                            <Column field="valorTotalSemDescontoFormatado" hidden />
                            <Column field="pvalortotalsemdesconto" header="Total sem Desconto" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.valorTotalSemDescontoFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalSemDesconto }}
                                </template>
                            </Column>
                            <Column field="valorTotalFormatado" hidden />
                            <Column field="pvalortotal" header="Total" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.valorTotalFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotal }}
                                </template>
                            </Column>
                            <Column field="valorPagoFormatado" hidden />
                            <Column field="pvalorpago" header="Total Pago" bodyStyle="text-align: right;" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.valorPagoFormatado }}
                                </template>
                                <template #footer>
                                    {{ valores[0].valorTotalPago }}
                                </template>
                            </Column>

                            <!----<template #footer>
                  <td colspan="12" style="text-align: right">{{valores[0].valorTotalOrigem}}</td>
                  <td>{{valores[0].valorTotalMulta}}</td>
                  <td>{{valores[0].valorTotalMulta}}</td>
                  <td>{{valores[0].valorTotalMulta}}</td>
                  <td>{{valores[0].valorTotalMulta}}</td>
                  <td>{{valores[0].valorTotalMulta}}</td>                  
              </template>-->
                        </DataTable>
                    </div>

                    <div class="col-12 d-flex justify-content-end p-1" v-if="false">
                        <Button label="Nova Pesquisa" type="button" class="p-button-primary" @click="novaPesquisa()" icon="pi pi-plus" />
                    </div>
                </div>

                <AtualizarVencimento ref="modalAtualizarVencimento" @refreshExtratos="refreshExtratos" />
                <Englobar ref="modalEnglobar" @refreshExtratos="refreshExtratos" />
            </TabPanel>

            <TabPanel header="Englobamentos">
                <Englobamentos ref="tabEnglobamentos" :idPessoa="idPessoa" @imprimirDam="imprimirDam" v-if="this.idPessoa" />
            </TabPanel>
        </TabView>
    </div>
</div>
</template>

<script>
import {
    FilterMatchMode,
    FilterOperator
} from "primevue/api";
import Util from "@/utils/util";
import moment from "moment";
import {
    VueRecaptcha
} from "vue-recaptcha";
import ExtratoService from "@/services/extrato.service";
import PortalService from "@/services/portal.service";
import ReportService from "@/services/report.service";
import AtualizarVencimento from "@/views/extrato/FormAtualizarVencimento.vue";
import Englobar from "@/views/extrato/FormEnglobar.vue";
import Englobamentos from "@/views/extrato/Englobamentos.vue";

export default {
    name: "Extrato",
    components: {
        VueRecaptcha,
        AtualizarVencimento,
        Englobar,
        Englobamentos
    },

    data() {
        return {
            pesq: true,
            loading: false,
            exibirInfoDados: false,
            filtro: "",
            selectedFiltro: null,
            filtrosConfiguracao: [],
            contribuinteTipo: null,
            contribuinteTipos: [],
            captchaState: true,
            extratoList: [],
            filters: {},
            filtroDataVencimento: "",
            selectedFiltroSituacao: null,
            filtrosSituacao: [{
                    name: "Todos",
                    key: "T"
                },
                {
                    name: "Débito",
                    key: "D"
                },
                {
                    name: "Pago",
                    key: "P"
                },
                {
                    name: "Cancelado",
                    key: "C"
                },
            ],
            selectedFiltroOrigem: null,
            filtrosOrigem: [{
                    name: "Todos",
                    key: "T"
                },
                {
                    name: "Cálculos Gerais",
                    key: "C"
                },
                {
                    name: "Dívida Ativa",
                    key: "D"
                },
                {
                    name: "ISSQN",
                    key: "IS"
                },
                {
                    name: "Taxas",
                    key: "TX"
                },
                {
                    name: "Auto Infração",
                    key: "A"
                },
                {
                    name: "ITBI",
                    key: "I"
                },
            ],
            selectedFiltroParcela: null,
            filtrosParcela: [{
                    name: "Todas",
                    key: "T"
                },
                {
                    name: "Parcelas",
                    key: "P"
                },
                {
                    name: "Única",
                    key: "U"
                },
            ],

            selecteds: [],
            idPessoa: null,
            inscricao: null,
            inscricoes: [],
            sales: null,
            valores: [],
            condicao: "",
            idTipo: "",
            idOrigemCalculoPermitido: []
        };
    },

    async created() {
        await this.$root.fetchFuncionalidades();
        await this.carregarConf();
        await this.initFilters();
        this.idOrigemCalculoPermitido = this.getIdOrigemCalculoPermitido();
        if (this.$route.query.opcao) {
            this.pesq = false;
            this.pesquisar();
        }

    },

    computed: {
        serverExec() {
            return this.$store.getters.getServerExec;
        },
        funcionalidades() {
            return JSON.parse(JSON.stringify([...this.$root.menuFuncionalidades]));
        },
        idFuncionalidade() {
            const funcionalidadesFiltered = this.funcionalidades.filter(
                (v) =>
                v.pagina && v.pagina !== null && v.pagina.toLowerCase() === "extrato"
            );
            return funcionalidadesFiltered[0] ? funcionalidadesFiltered[0].id : null;
        },
        idCliente() {
            return this.$store.getters.getIdCliente;
        },
        idUsuario() {
            return this.$store.getters.getIdUsuario;
        },
        hasContribuinteTipos() {
            return this.contribuinteTipos.length > 0;
        },
        disableEnglobar() {
            return this.selecteds === null || this.selecteds.length <= 1;
        },
        disableImprimirExtrato() {
            return this.selecteds === null || this.selecteds.length < 1;
        }
    },

    methods: {
        async carregarConf() {
            if (this.idFuncionalidade && this.idFuncionalidade !== "") {
                try {
                    this.$store.dispatch("SET_ENABLE_SPINNER", true);
                    this.filtrosConfiguracao = await PortalService.findConfFuncionalidade(
                        this.idCliente,
                        this.idFuncionalidade
                    );
                } catch (errorMessage) {
                    console.log(errorMessage);
                    this.alerta("error", "Houve um problema ao listar as configurações!");
                } finally {
                    this.selectedFiltro = this.filtrosConfiguracao[0];
                    this.$store.dispatch("SET_ENABLE_SPINNER", false);
                }
            }
        },

        async findContribuinteTipo(event) {
            try {
                const tipoFiltro = event.value && event.value.tipoFiltro;
                this.$store.dispatch("SET_ENABLE_SPINNER", true);
                this.contribuinteTipos =
                    await PortalService.findFuncionalidadeContribuinteTipo(
                        this.idCliente,
                        this.idFuncionalidade,
                        tipoFiltro
                    );

            } catch (errorMessage) {
                console.log(errorMessage);
                this.alerta("error", "Houve um problema ao listar as filtros!");
            } finally {
                this.contribuinteTipo = this.contribuinteTipos[0];
                this.$store.dispatch("SET_ENABLE_SPINNER", false);
            }
        },

        async pesquisar() {
            try {
                this.$store.dispatch("SET_ENABLE_SPINNER", true);

                if (this.$route.query.opcao) {
                    if (Util.retirarMascara(this.$route.query.opcao).length == '11') {
                        this.idTipo = 'CPF';
                    } else {
                        this.idTipo = 'CNPJ';
                    }

                    for (let index = 0; index < this.filtrosConfiguracao.length; index++) {
                        if (this.idTipo == this.filtrosConfiguracao[index].tipoFiltro) {
                            this.idTipo = this.filtrosConfiguracao[index].id;
                        }
                    }
                    let cpf_cnpj = await ExtratoService.criptografar(this.$route.query.opcao, 'D');

                    const filtroVenc = moment(this.filtroDataVencimento).format(
                        "YYYY-MM-DD"
                    );

                    this.extratoList = await ExtratoService.findAll(
                        this.idCliente,
                        cpf_cnpj,
                        this.idTipo,
                        filtroVenc,
                        this.selectedFiltroSituacao.key,
                        this.selectedFiltroOrigem.key,
                        this.selectedFiltroParcela.key
                    );

                } else {

                    const filtroVenc = moment(this.filtroDataVencimento).format(
                        "YYYY-MM-DD"
                    );

                    if (
                        this.inscricoes.length > 0 &&
                        this.inscricoes[0].inscricaoMunicipal
                    ) {
                        this.filtro = this.inscricoes[0].inscricaoMunicipal;
                    }

                    this.extratoList = await ExtratoService.findAll(
                        this.idCliente,
                        this.filtro,
                        this.selectedFiltro.id,
                        filtroVenc,
                        this.selectedFiltroSituacao.key,
                        this.selectedFiltroOrigem.key,
                        this.selectedFiltroParcela.key
                    );
                }

                if (this.extratoList.length > 0) {
                    this.somatorio();
                }

                this.idPessoa = this.extratoList[0].pidpessoa;
            } catch (errorMessage) {
                console.log(errorMessage);
                this.alerta("error", "Houve um problema ao listar o Extrato!");
            } finally {
                if (this.extratoList.length > 0) {
                    this.exibirInfoDados = false;
                    this.pesq = false;
                } else {
                    this.exibirInfoDados = true;
                }
                this.$store.dispatch("SET_ENABLE_SPINNER", false);
            }
        },

        async getIdOrigemCalculoPermitido() {
            return this.idOrigemCalculoPermitido = await ExtratoService.getIdOrigemPermitido(this.idCliente);
        },

        somatorio() {
            let valorTotalOrigem = 0;
            let valorTotalMulta = 0;
            let valorTotalJuros = 0;
            let valorTotalCorrecao = 0;
            let valorTotal = 0;
            let valorTotalPago = 0;
            let valorTotalDesconto = 0;
            let valorTotalSemDesconto = 0;
            let valorTotalMultaInscricao = 0;

            //Somatório Valor Origem
            for (let sale of this.extratoList) {
                valorTotalOrigem += sale.pvalor;
            }

            //Somatório Valor Multa
            for (let sale of this.extratoList) {
                valorTotalMulta += sale.pmulta;
            }

            //Somatório Valor Juros
            for (let sale of this.extratoList) {
                valorTotalJuros += sale.pjuros;
            }

            //Somatório Valor Correção
            for (let sale of this.extratoList) {
                valorTotalCorrecao += sale.pcorrecao;
            }

            //Somatório Valor Desconto
            for (let sale of this.extratoList) {
                valorTotalDesconto += sale.pdescontototal;
            }

            //Somatório Valor Total sem Desconto
            for (let sale of this.extratoList) {
                valorTotalSemDesconto += sale.pvalortotalsemdesconto;
            }

            //Somatório Valor Total
            for (let sale of this.extratoList) {
                valorTotal += sale.pvalortotal;
            }

            //Somatório Valor Total Pago
            for (let sale of this.extratoList) {
                valorTotalPago += sale.pvalorpago;
            }

            //Somatório Valor Total Multa Inscrição
            for (let sale of this.extratoList) {
                valorTotalMultaInscricao += sale.pmultainscricao;
            }

            this.valores = [{
                valorTotalOrigem: this.formatCurrency(valorTotalOrigem),
                valorTotalMulta: this.formatCurrency(valorTotalMulta),
                valorTotalJuros: this.formatCurrency(valorTotalJuros),
                valorTotalCorrecao: this.formatCurrency(valorTotalCorrecao),
                valorTotalDesconto: this.formatCurrency(valorTotalDesconto),
                valorTotalSemDesconto: this.formatCurrency(valorTotalSemDesconto),
                valorTotal: this.formatCurrency(valorTotal),
                valorTotalPago: this.formatCurrency(valorTotalPago),
                valorTotalMultaInscricao: this.formatCurrency(valorTotalMultaInscricao),
            }, ];

            return this.valores;
        },

        refreshExtratos() {
            console.log(this.$refs.tabEnglobamentos.fetchEnglobamentos());
            this.pesquisar();
            this.$refs.tabEnglobamentos.fetchEnglobamentos();
        },

        initFilters() {
            this.filtroDataVencimento = new Date();
            this.selectedFiltroSituacao = this.filtrosSituacao[1];
            this.selectedFiltroOrigem = this.filtrosOrigem[0];
            this.selectedFiltroParcela = this.filtrosParcela[0];
            this.filters = {
                global: {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                porigem: {
                    operator: FilterOperator.AND,
                    constraints: [{
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }, ],
                },
                pdetalhamentoorigem: {
                    operator: FilterOperator.AND,
                    constraints: [{
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }, ],
                },
                psituacao: {
                    operator: FilterOperator.AND,
                    constraints: [{
                        value: null,
                        matchMode: FilterMatchMode.STARTS_WITH
                    }, ],
                },
            };
        },

        novaPesquisa() {
            this.extratoList = [];
            this.pesq = true;
            if (this.$refs.recaptcha) {
                this.$refs.recaptcha.reset();
            }
            this.exibirInfoDados = false;
        },

        async imprimirDam(data) {
            try {
                this.$store.dispatch("SET_ENABLE_SPINNER", true);

                await this.$root.getTrbToken;

                /**
                 * Verifica se o DAM está vencido 
                 * Variável data.datavencimento botão imprimir DAM englobado
                 * Variável data.pdatavencdam botão imprimir DAM individual tela de extrato
                 */
                if (data.datavencimento) {
                    this.data_vencimento = data.datavencimento;
                } else if (data.pdatavencdam) {
                    this.data_vencimento = data.pdatavencdam;
                }

                if (!Util.validarDataMenor(this.data_vencimento, Util.diaAtual("T"))) {
                    this.alerta(
                        "error",
                        "O DAM está vencido, clique no botão ao lado para atualizar seu vencimento"
                    );
                    return false;
                }

                const imprimirDam = {
                    idCalculoParcelados: data.pid,
                    token: this.$store.getters.getTrbToken,
                };
                await ExtratoService.imprimirDam(this.serverExec, imprimirDam);
            } catch (errorMessage) {
                console.log(errorMessage);
                this.alerta("error", "Houve um problema ao imprimir DAM!");
            } finally {
                this.$store.dispatch("SET_ENABLE_SPINNER", false);
            }
        },

        handleSuccess() {
            this.captchaState = false;
        },

        handleError() {
            this.captchaState = true;
        },

        validarDocumento() {
            var validar = false;
            validar = Util.validarCPFCNPJ(this.documento);

            if (!validar && this.documento) {
                this.$root.$emit(
                    "alert:warn",
                    "CPF/CNPJ " + this.documento + " inválido!"
                );
                this.documento = "";
            }

            return validar;
        },

        imprimirDetalhamento(id) {
            this.processando(true);
            ReportService.imprimir("trb_detalhamento_extrato", [{
                        param: "RP_ID_CLIENTE",
                        string: this.idCliente,
                    },
                    {
                        param: "RP_ID_REPAR",
                        string: id,
                    },
                ])
                .then(
                    () => {},
                    (error) => {
                        this.$root.$emit("alert:warn", "Erro ao Imprimir!");
                        console.log(error);
                    }
                )
                .then(() => {
                    this.processando(false);
                });
        },

        imprimirExtrato() {

            const idMarcados = this.extratoList;

            this.condicao += " WHERE parc.id in  ( ";
            for (let index = 0; index < idMarcados.length; index++) {
                if (index + 1 == idMarcados.length) {
                    this.condicao += "''" + idMarcados[index].pid + "'')";
                } else {
                    this.condicao += "''" + idMarcados[index].pid + "'' ,";
                }
            }

            this.processando(true);
            ReportService.imprimir("trb_extrato_novo", [{
                        param: "RP_ID_CLIENTE",
                        string: this.idCliente,
                    },
                    {
                        param: "P_Condicao",
                        string: " ('" + this.idCliente + "','" +
                            this.condicao + "', 'S', 'S', '" + Util.diaAtual('T') + "', NULL, NULL, NULL)"
                    },
                    {
                        param: "RP_ID_USUARIO",
                        string: this.idUsuario
                    }
                ])
                .then(
                    () => {},
                    (error) => {
                        this.condicao = "";
                        this.selecteds = null;
                        this.$root.$emit("alert:warn", "Erro ao Imprimir!");
                        console.log(error);
                    }
                )
                .then(() => {
                    this.condicao = "";
                    this.selecteds = null;
                    this.processando(false);
                });
        },

        processando(show) {
            this.loading = show || false;
            this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
        },

        formatText(text) {
            const lowerCase = text.toLowerCase();
            return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
        },

        atualizarVencimento(data) {
            this.$refs.modalAtualizarVencimento.show(data);
        },

        englobar() {
            if (this.selecteds === null || this.selecteds.length <= 1) {
                this.$toast.add({
                    severity: "warn",
                    summary: "Selecione dois ou mais regitros para englobar.",
                    life: 3000,
                });
            } else if (!this.verificaDadosMarcados()) {
                this.$toast.add({
                    severity: "error",
                    summary: "Débitos marcados não autorizados para englobamento",
                    life: 3000,
                });
            } else {
                this.$refs.modalEnglobar.show(this.selecteds);
            }
        },

        alerta(type, msg) {
            this.$toast.add({
                severity: type,
                summary: msg,
                life: 3000,
            });
        },

        buscarInscricoes() {
            if (this.filtro) {
                this.inscricoes = ExtratoService.findInscricao(
                    this.idCliente,
                    this.filtro,
                    this.selectedFiltro.id,
                    this.contribuinteTipo.id
                );

                Promise.resolve(this.inscricoes).then((inscricoes) => {
                    this.inscricoes = inscricoes;
                });
            }
        },

        returnLabel() {
            return this.selectedFiltro && this.selectedFiltro.tipoFiltro == "CNPJ" ?
                "CNPJ" :
                this.selectedFiltro && this.selectedFiltro.tipoFiltro == "CPF" ?
                "CPF" :
                this.selectedFiltro &&
                this.selectedFiltro.tipoFiltro == "CNPJ_INSCRIÇÃO" ?
                "CNPJ" :
                this.selectedFiltro &&
                this.selectedFiltro.tipoFiltro == "CPF_INSCRIÇÃO" ?
                "CPF" :
                "Inscrição Municipal";
        },

        formatCurrency(value) {
            return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },

        verificaIdOrigemCalculo(idOrigemCalculo) {
            if(!this.idOrigemCalculoPermitido[0]){
                return true;
            }

            let arrayIdCalculo = this.idOrigemCalculoPermitido[0].split(',');
            for (let index = 0; index < arrayIdCalculo.length; index++) {
                const permitido = arrayIdCalculo[index];
                if (idOrigemCalculo == permitido) {
                    return false;
                } else {
                    return true;
                }
            }
        },

        verificaDadosMarcados() {
            let erro = 0;
            for (let index = 0; index < this.selecteds.length; index++) {
                const element = this.selecteds[index];
                if (!this.verificaIdOrigemCalculo(element.pidorigemcalculo)) {
                    erro++;
                }
            }

            if (erro == 0) {
                return true;
            } else {
                return false
            }
        },
    },
};
</script>
