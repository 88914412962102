<template>
  <div class="row row-no-gutters">

    <div class="col-12 p-1">

      <DataTable
        :loading="loading"
        paginator
        showGridlines
        dataKey="pid"
        ref="englobamentosTable"
        class="p-datatable-customers"
        :value="englobamentos"
        :rows="15"
        :rowHover="true"
        v-model:filters="filters"
        filterDisplay="menu"
        responsiveLayout="scroll"
      >
        <template #loading>
          Carregando Extrato...
        </template>
        <Column field="actionsButton" header="Ações" bodyStyle="text-align: center">
          <template #body="slotProps">
            <Button
              type="button"
              icon="pi pi-print"
              title="Imprimir"
              @click="$emit('imprimirDam', slotProps.data)"
              class="p-button-sm p-button-grid"
            />
          </template>
        </Column>

        <Column field="ano" header="Ano" bodyStyle="text-align: center" sortable>
          <template #body="{ data }"> {{ data.ano }} </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Pesq. Ano"
            />
          </template>
        </Column>

        <Column field="pcodigo" header="Código" sortable>
          <template #body="{ data }"> {{ data.codigoparcelamento }} </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Pesq. Código"
            />
          </template>
        </Column>

        <Column field="pdatavencdam" header="Data de Vencimento" bodyStyle="text-align: center" sortable>
          <template #body="{ data }"> {{ data.dataVencFormatada }} </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Pesq. Vencimento"
            />
          </template>
        </Column>

        <Column field="pregistrado" header="Registrado" bodyStyle="text-align: center" sortable>
          <template #body="{ data }"> {{ data.dataRegistroFormatada }} </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Pesq. Registrado"
            />
          </template>
        </Column>

        <Column field="pmensagem" header="Mensagem" sortable>
          <template #body="{ data }"> {{ data.mensagem }} </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Pesq. Mensagem"
            />
          </template>
        </Column>

      </DataTable>
    </div>
  
  </div>  
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import ExtratoService from "@/services/extrato.service";

export default {
  name: "Englobamentos",

  props: {
    idPessoa: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      englobamentos: [],
      fields: {},
    };
  },

  mounted() {
    this.fetchEnglobamentos();
  },

  computed: {
    filters() {
      return {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  },

  methods: {

    async fetchEnglobamentos() {
      try {
        this.$store.dispatch('SET_ENABLE_SPINNER', true);

        this.englobamentos = await ExtratoService.findEnglobamentos(this.idPessoa);



      } catch (errorMessage) {
        console.log(errorMessage);
        this.$toast.add({
          severity: "error",
          summary: "Houve um problema ao listar os Englobamentos!",
          life: 3000,
        });
      } finally {
        this.$store.dispatch('SET_ENABLE_SPINNER', false);
      }
    }

  },
  
};
</script>

<style>

</style>